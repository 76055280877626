<template>
  <v-container class="produttori-locali-detail">
    <Breadcrumb :items="breadcrumb" />

    <h1 v-html="title"></h1>
    <div v-if="page.video_url" class="video-responsive">
      <iframe
        width="100%"
        height="100%"
        :src="loadURL(page.video_url)"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
    <p v-html="content" />
  </v-container>
</template>
<style lang="scss">
.video-responsive {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    min-height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
<script>
import CMService from "~/service/cmService";
import loadURL from "~/mixins/loadURL";
import page from "@/commons/mixins/page";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

export default {
  components: { Breadcrumb },
  name: "ProduttoriDetail",
  mixins: [loadURL, page],

  methods: {
    async fetchPost(slug) {
      const res = await CMService.getCustomPostBySlug("produttore", slug);
      if (res && res.length) {
        this.page = res[0];
      }
    }
  },
  mounted() {
    this.fetchPost(this.$route.params.postName);
  },
  watch: {
    "$route.params.postName"() {
      this.fetchPost(this.$route.params.postName);
    }
  }
};
</script>
